<template>
  <div class="addGoods">
    <el-form class="form" ref="form" :model="form" :rules='rules' label-width="200px">
      <el-form-item label="分账主体（代理商/门店）" prop="unitNo" >
        <AgentTree
          :props="props"
          v-model="form.unitNo"
          placeholder="请选择分账主体"
          style="width: 300px"
          :selectKey='selectKey'
        ></AgentTree>
      </el-form-item>
      <el-form-item label="分账商品类型" prop="type">
        <el-select
          v-model="form.type"
          style="width: 300px"
          @change="getGoodsType"
          placeholder="请选择分账商品类型："
        >
          <el-option
            v-for="opt in GOODS_TYPE"
            :label="opt.label"

            :key="opt.value"
            :value="opt.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分账商品" prop="commodityNo">
        <el-cascader
          :options="options"
          style="width: 300px"
          placeholder="请选择分账商品"
          @change="getSelectGoods"
          v-model="form.commodityNo"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="配置方式" prop="method">
        <el-select
          style="width: 300px"
          placeholder="请选择配置方式"
          v-model="form.method"
        >
          <el-option v-for="item in methodOptions" :value="item.value" :key="item.label" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div>
      <h3 style="background-color: #fff" class="mt20 mb20">商品方案信息</h3>
      <el-table :data="goodsTable" style="width: 100%">
        <el-table-column prop="agentName" label="代理商"> </el-table-column>
        <el-table-column prop="storeName" label="门店">
        </el-table-column>
        <el-table-column prop="commodityType" label="商品类型">
          <template slot-scope="{ row }">
            {{ $changeObj(GOODS_TYPE)[row.commodityType] }}
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="商品规格">
        </el-table-column>
        <el-table-column prop="batteryCapacity" label="方案名称">
          <template slot-scope="{row}">
            {{row.planInfo.commodityName}}
          </template>
        </el-table-column>
        <el-table-column prop="schemes[0].singleRent" label="单期租金">
          <template v-if="row.schemes[0].singleRent" slot-scope="{row}">
            {{row.planInfo.singleRent}}元
          </template> </el-table-column>
        <el-table-column prop="schemes[0].validDay" label="有效时长">
          <template v-if="row.schemes[0].validDay" slot-scope="{row}">
            {{row.planInfo.validDay}}天
          </template>
        </el-table-column>
        <el-table-column prop="vehicleBrand" label="商品状态">
          <template slot-scope="{row}">
             {{ $changeObj(GOODS_STATUS)[row.status] }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-button type="primary" @click="addScheme" class="mt20 mb10" v-if="schemeList.length < 5">新增分账人</el-button>
    <div class="scheme mt20">
      <div v-for="(scheme, index) in schemeList" :key="index" class="df">
        <el-form label-width="100px" :inline="true"  :model="scheme">
          <el-form-item label="分账人组织" prop="funitNo">
          <HaiLiTree
            v-model="scheme.funitNos"
            placeholder="请选择分账主体"
            style="width: 300px"
            @sendRole='getRole'
            :index='index'
            :selectKey='scheme.selectKey'
          >
          </HaiLiTree>
          </el-form-item>

          <el-form-item label="分账人角色" prop="roleStr" >
            <el-input
              v-model="scheme.roleStr"
              disabled
              style="width: 200px"
              placeholder="请输入分账人角色"
            ></el-input>
          </el-form-item>
            <el-form-item label="分账方式"  prop="ftype">
              <el-select
              v-model="scheme.ftype"
              style="width: 200px"
              placeholder="请选择分账方式"
            >
              <el-option
                v-for="opt in optionsType"
                :label="opt.label"
                :key="opt.value"
                :value="opt.value"
              ></el-option>
            </el-select>
          </el-form-item>
           <!--  -->

          <el-form-item label="分账金额" prop="fratio" v-if="form.method == '1' ">
            <el-input
              @keyup.native="scheme.fratio=scheme.fratio.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/\.{2,}/g,'.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
              v-model="scheme.fratio"
              style="width: 200px"
              placeholder="请选择分账金额"
            ></el-input>
          </el-form-item>
          <!--  -->
          <el-form-item label="分账比例" prop="fratio" v-else>
            <el-input
              v-model="scheme.fratio"
              style="width: 200px"
              placeholder="请选择分账比例"
            ></el-input>
            <span  class='ml10' >%</span>
          </el-form-item>

          <el-form-item label="备注" prop="frole" >
            <el-input
              v-model="scheme.frole"
              style="width: 200px"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item class="ml32">
            <el-button type="primary" @click="deleteBtn(index)">删除</el-button>
          </el-form-item>
        </el-form>
        <div class="btn mt10" style="flex-shrink: 0" v-if="index === schemeList.length - 1">
          <i
            class="el-icon-circle-plus-outline curp"
            v-if="schemeList.length < 5"
            @click="addScheme"
          ></i>
          <i class="el-icon-remove-outline curp" @click="delScheme(index)"></i>
        </div>
      </div>
      <div style="margin-left: 20px; font-size: 14px">说明:资金方无借据或借据还款到期/月度还款后金额已还至借据总额时，原分至资金方金额将分至海狸（无资金方），可在结算管理中发起结算。</div>
    </div>

    <div style="margin: 50px 0 0 0;">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { RELATION_GOODS_TYPE, GOODS_STATUS, PRODUCT_TYPE ,SUB_BILL_TYPE ,GOODS_TYPE} from "@/js/constant";
import { getProductList, updateDistributeRelation ,findDistributeRelation } from "@/api/product";
import {addDisRelation ,editDisRelation} from "@/api/cash";
import  AgentTree from '@/components/AgentTree';
import { getGoodsList } from "@/api/goods";
import  HaiLiTree from '@/components/HaiLiTree';

export default {
  data() {
    return {
      methodOptions:[
        {
          value:0,
          label:'比例'
        },
        {
          value:1,
          label:'金额'
        },
      ],
      goodsType:'',//商品类型选择
      schemes:[],//商品名称
      goodsList:[],//商品管理数据
      form: {
        unitNo: [],
        commodityNo:[],
        type: 1,
        method:'',
      },
      // 编辑的时候老的值
      oldCommodityNo: '',
      options:[],//分账商品二级联动数据
      optionsType:[{//分账方式二级联动数据
        label:'资金流穿透',
        value:1,
      },
      {
          label:'系统内记账',
          value:2,
      }],
      rules: {
        unitNo: [
          { required: true, message: "请选择代分账主体  ", trigger: "change" }
        ],
        type: [
          { required: true, message: "请选择代商品类型", trigger: "change" }
        ],
        commodityNo: [
          { required: true, message: "请选择分账商品", trigger: "change" }
        ],
        method: { required: true, message: "请选择代分账方式  ", trigger: "change" }
      },
      disaled: false,
      RELATION_GOODS_TYPE,
      SUB_BILL_TYPE,
      GOODS_TYPE,
      productList: [],
      /** 选择的产品 */
      selectProductList: [],
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      goodsTable: [],
      GOODS_STATUS,
      // 分账人列表
      schemeList: [],
      selectKey: '',
      /** 海狸组织 */
      haiLiUniNo: [],
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      this.findDistributeRelation();
    }

  },
  watch: {
    'form.unitNo': {
      handler(val) {
        this.getGoodsList()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    deleteBtn(i){
      console.log(i,'i');
      this.$delete(this.schemeList,i);
    },
    // 进入编辑获得数据
    async findDistributeRelation(){
      const params = {
        commodityNo:this.$route.query.commodityNo
      }
      const res = await findDistributeRelation(params);
      console.log(res,'编辑res--------');
      const row = res.ldata[0];
      // 回显
      this.selectKey = row.relationUnitNo;
      this.form.type = +row.type
      this.form.method = +row.method;

        res.ldata.forEach(el => {
          const obj = {
            frole: el.role,
            funitNo: '',
            funitNos: [],
            selectKey: el.unitNo,
            commodityNo:'',
            fratio: el.ratio,
            ftype: +el.fType
          }
          this.schemeList.push(obj)
        })

    },
    async getGoodsList(){
      if (!this.form.unitNo.length || !this.form.type) {
        return
      }
      const params = {
        beginIndex:0,
        pageSize:100,
        unitNo:this.form.unitNo[this.form.unitNo.length - 1],
        type: this.form.type,
      }
      const res = await getGoodsList(params);
      this.goodsList = res.ldata;
      // 以下是分账商品二级联动处理数据
      const arr = res.ldata.map(el=>{
         let obj = {};
         obj.value = el.commodityNo;
         obj.label = el.specification;//这里要改成commodityName
         obj.commodityNo = el.commodityNo;
         obj.children = [];
         el.schemes.forEach(el2=>{
            let obj2 = {};
            obj2.value = el2.schemeNo;
            obj2.label = el2.commodityName;
            obj.children.push(obj2);
         })
         return obj;
      })
      this.options = arr;
      if (this.$route.query.type == "edit") {
        arr.forEach(el => {
          el.children && el.children.forEach(el2 => {
            if (el2.value == this.$route.query.commodityNo) {
              this.oldCommodityNo = el2.value
              this.form.commodityNo= [el.value, el2.value]
              this.getSelectGoods( this.form.commodityNo)
            }
          })
        })
      }
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.schemeList.length) {
            this.$message.error('请添加分账人')
            return
          }
          this.disaled = true;
          // 后端要{reqlist:JSON.stringfy([{},{}])}的数据,这里做发送数据前处理
          // 分账方式一个框，要选出2个数据，如果type是2位数，就是acountType,type就为1；如果type=2,不变，facountType不传；
          const newSchemeList = JSON.parse(JSON.stringify( this.schemeList))
          newSchemeList.forEach((el,i)=>{
            // el.ftype = el.ftype[el.ftype.length - 1];
            if(el.funitNos.length != 1){
              el.funitNo = el.funitNos[el.funitNos.length - 1];
            }else{
              el.funitNo = el.funitNos[0];
            }
            if(el.ftype == 1){
              el.faccountType = 0;
              el.ftype = 1;
            } else {
              el.ftype = 2;
            }
            el.commodityNo = this.form.commodityNo[1];
          })
          const params = {
            reqList:JSON.stringify(newSchemeList),
            commodityNo:this.form.commodityNo[1],
            method:this.form.method,
            oldCommodityNo: this.oldCommodityNo
          }
          console.log(params,'params-----');
          const unitNo = this.form.unitNo[this.form.unitNo.length - 1]
          params.unitNo  = unitNo;
          try {
            if (this.$route.query.type == "edit") {//编辑
              params.number = this.$route.query.number;
              params.orgNo = this.$route.query.orgNo;
              await updateDistributeRelation(params);
            } else {//新增
              const res = await addDisRelation(params);
            }
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push('/leaseholdMgmt/subBill');
            }, 1000);
          } catch (error) {
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/subBill');

    },
    handleSelectionChange(row) {
      this.selectProductList = row;
    },
    /** 获取选择的商品 */
    getSelectGoods(vals) {
      const val = vals[0];
      const planVal = vals[1]
      const row = this.goodsList.find(el => el.commodityNo == val)
      const plan = (row.schemes || []).find(el => el.schemeNo == planVal)
      row.planInfo = plan
      this.goodsTable = [row]
    },
     // 添加分账人信息
    addScheme() {
      if(!this.form.method && this.form.method !== 0){
        this.$message.warning('请选择配置方式');
        return;
      }
      this.schemeList.push({
        frole: "",
        funitNo: '',
        funitNos: [],
        ftype:'',
        faccountType:'',
        commodityNo:'',
        fratio:''
      });
    },
    delScheme(index) {
      this.schemeList.forEach((el, i, arr) => {
        if (i == index) {
          arr.splice(i, 1);
        }
      });
    },
    getGoodsType() {
      this.getGoodsList()
    },
    getRole(i, str) {
      this.schemeList[i].roleStr = str
    }
  },
  components: {
    AgentTree,
    HaiLiTree,
  }
};
</script>

<style scoped lang="less">
.addGoods {
  padding-bottom: 100px;
  .scheme {
    i {
      font-size: 28px;
      margin-left: 10px;
    }
  }
}
.pMarginLeft{
  margin-left: 50px;
}
.flex{
  display: flex;
  justify-content: space-around;
}

</style>
