<template>
  <div class="addGoods">
    <div>
      <h3 style="background-color: #fff" class="mt20 mb20">对账单信息</h3>
      <el-table :data="messageList" style="width: 100%">
        <el-table-column prop="number" label="对账单编号"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="{row}"}>
            {{row.createTime | timeToDate}}
          </template>
        </el-table-column>
        <el-table-column prop="unitName" label="对账主体"> </el-table-column>
        <el-table-column prop="status" label="对账单状态">
          <template slot-scope="{row}">{{$changeObj(DIRECTION_STATUS)[row.status]}}</template>
        </el-table-column>
        <el-table-column prop="money" label="需结算金额">
        </el-table-column>
        <el-table-column prop="updateMoney" label="调整项金额">
        </el-table-column>
        <el-table-column prop="realMoney" label="实际返润金额">
        </el-table-column>
        <el-table-column>
          <template slot-scope="{row}" >
            <el-button v-if="row.status === 1 " size="mini" type="primary" @click="handleOrder">处理完成</el-button>
            <el-button v-if="+$route.query.status === 2 " size="mini" type="primary" @click="confirm">完成确认</el-button>
            <!-- v-if="+$route.query.status !== 3" -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <h3 class="mt20 bgf">对账详情</h3>
    <el-table :data="detailList" style="width: 100%">
      <el-table-column prop="type" label="商品类型"> 
        <template slot-scope="{row}"}>
            {{$changeObj(PRODUCT_TYPE)[row.type]}}
          </template>
      </el-table-column>
      <el-table-column prop="specification" label="商品规格">
      </el-table-column>
      <el-table-column prop="name" label="方案名称">
      </el-table-column>
      <el-table-column prop="num" label="数量">
      </el-table-column>
       <el-table-column prop="singleMoney" label="单笔收益">
      </el-table-column>
       <el-table-column prop="money" label="佣金">
      </el-table-column>
    </el-table>
    <div class="dfc mb20 mt20">
      <span class="mr20 fw600">调整项信息</span>
      <!-- 这里 -->
      <el-button  v-if="!disabledFlag" type="primary" size="medium"  @click="addScheme">新增调整项</el-button>
    </div>
    <div class="scheme">
      <div v-for="(scheme, index) in schemeList" :key="index" class="df">
        <el-form label-width="100px" :inline="true">
          <el-form-item label="调整项类型：">
            <el-select class="selectBtn" v-model="scheme.type" placeholder="请选择" 
              :disabled="disabledFlag">
              <el-option
                v-for="item in ADJUSTMENT_STATUS"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调整项目：">
            <el-input
              class="bgfffInput"
              :disabled=" disabledFlag"
              maxlength="10"
              v-model="scheme.reason"
              style="width: 200px"
              placeholder="请输入调整项目"
            ></el-input>
          </el-form-item>
          <el-form-item label="调整金额：">
            <el-input
              class="bgfffInput"
              :disabled=" disabledFlag"
              min="0"
              type="number"
              v-money
              v-model="scheme.moneyString"
              style="width: 200px"
              placeholder="请输入调整金额"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 这里 -->
        <div class="btn mt10" v-if="(index === schemeList.length - 1) && !disabledFlag">
          <i
            class="el-icon-circle-plus-outline curp"
            v-if="schemeList.length < 10"
            @click="addScheme"
          ></i>
          <i class="el-icon-remove-outline curp" @click="delScheme(index)"></i>
        </div>
      </div>
    </div>
    <!-- <div style="margin: 50px 0 0 0;"> -->
      <!-- <el-button type="info" @click="goBack">返回</el-button>
    </div> -->
  </div>
</template>

<script>

import { accountCheckDetail,handleOrder,confirm} from "@/api/goods";
import {ADJUSTMENT_STATUS,RECONCILIATION_STATUS ,COMMODITY_TYPE,DIRECTION_STATUS,PRODUCT_TYPE} from "@/js/constant";
// import { queryHaiLiUnitTree } from "@/api/common";
// import { handleTree } from "@/utils/tool";
export default {
  data() {
    return {

      operateAuthority:false,
      value:'',
      PRODUCT_TYPE,
      DIRECTION_STATUS,
      COMMODITY_TYPE,
      RECONCILIATION_STATUS,
      ADJUSTMENT_STATUS,
      messageList:[],
      detailList:[],
      form: {
        agentNo: "",
        commodityType: 1,
        specification: "",
        status: 1
      },
      rules: {
        name: [
          { required: true, message: "请输入供应商名称", trigger: "blur" }
        ],
        subType: [
          { required: true, message: "请选择供应商类型", trigger: "blur" }
        ]
      },
      disaled: false,
      /** 代理商 */
      agentList: [],
      /*关联产品*/
      productList: [],
      /** 选择的产品 */
      selectProductList: [],
      /** 方案 */
      schemeList: [],
      props: {
        value: "unitNo",
        label: "name"
      },
      unitNos: []
    };
  },
  // watch: {
  //   unitNos: function(unitNos) {
  //     let formData = { ...this.form };
  //     formData.unitNo = unitNos[unitNos.length - 1] || "";
  //     this.form = formData;
  //   }
  // },
  mounted() {
    this.accountCheckDetail();
  },
  computed:{
    disabledFlag(){
      // 有权限&&状态为1时，可修改，2,3状态，有权限也不能修改
      if(+this.$route.query.status === 1 && this.operateAuthority){
        return false;
      }
      return true;
    }
  },
  methods: {
    // 完成确认按钮
    async confirm(){
      try{
        const params = {
          number:this.messageList[0].number
        };
        const res = await confirm(params);
        this.accountCheckDetail();
        this.$router.push('/leaseholdMgmt/viewBill');
      }catch(e){
        console.log(e,'e');
      }
    },
    // 处理对账单(处理完成按钮)
    async handleOrder(){
      try{
        const params = {
          no:this.messageList[0].number,
          list:JSON.stringify(this.schemeList)
        };
        const res = await handleOrder(params);
        this.$message.success('处理成功');
        this.$router.push('/leaseholdMgmt/bill');
      }catch(e){
        console.log(e,'e');
      }
    },
     // 对象详情 
    async accountCheckDetail() {
      try{
        const params = {
          number: this.$route.query.number
        };
        const res = await accountCheckDetail(params);
        this.detailList = res.mdata.detail;
        this.messageList = [res.mdata.message];
        this.operateAuthority = res.mdata.operateAuthority;//是否有权改调整项和增减
        // res.mdata.update = [{moneyString:1,reason:2,type:1}]
        const updateArr = res.mdata.update.map(el => {
          const obj = {};
          obj.moneyString = el.moneyString;
          obj.reason = el.reason;
          obj.type = el.type;
          return obj;
        });
        console.log(updateArr,'updateArr');
        this.schemeList = updateArr;
      }catch(e){
        console.log(e,'e');
      }
    },
  
    handleSelectionChange(row) {
      this.selectProductList = row;
    },
    // 添加方案
    addScheme() {
      if(this.schemeList && this.schemeList.length < 10){
        this.schemeList.push({
          reason:'',
          moneyString:'',
          type:''
        });
      }
      
    },
    // 删除方案
    delScheme(index) {
      this.schemeList.forEach((el, i, arr) => {
        if (i == index) {
          arr.splice(i, 1);
        }
      });
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
.addGoods {
  padding-bottom: 100px;
  .scheme {
    i {
      font-size: 28px;
      margin-left: 10px;
    }
  }
}

// disabled  input黑子白底
.el-input.is-disabled .el-input__inner{
  color:black;
  background:#fff;
}

</style>
