import axios from "../js/ajax";
/** 商品管理列表 */
export const getGoodsList = params =>
  axios.get("/managementPlatform/lease/queryCommodityList", {
    params,
    loading: true
  });
/** 切换状态 */
export const changeGoodsStatus = params =>
  axios.get("/managementPlatform/lease/updateCommodityStatus", { params });
/** 删除商品 */
export const delGoods = params =>
  axios.get("/managementPlatform/lease/deleteCommodity", { params });
/** 新增编辑商品管理 */
export const addOrUpdateCommodity = params =>
  axios.postJson("/managementPlatform/lease/addOrUpdateCommodity", params);
/** 商品详情 */
export const queryCommodityDetail = params =>
  axios.get("/managementPlatform/lease/queryCommodityDetail", { params });

/** 新增保险列表 */
export const queryInsuranceList = params =>
  axios.get("/managementPlatform/lease/queryInsuranceList", {
    params,
    loading: true
  });
/** 新增编辑保险 */
export const saveInsurance = params =>
  axios.postJson("/managementPlatform/lease/addOrUpdateInsurance", params);
/** 删除保险 */
export const deleteInsurance = params =>
  axios.get("/managementPlatform/lease/deleteInsurance", { params });
/** 保险强制购买 */
export const updateInsuranceIsCompelBuy = params =>
  axios.get("/managementPlatform/lease/updateInsuranceIsCompelBuy", { params });
/** 保险管理列表 */
export const queryBuyInsuranceRecordList = params =>
  axios.get("/managementPlatform/lease/queryBuyInsuranceRecordList", {
    params
  });

/** 保险详情列表*/
export const queryInsuranceDetail = params =>{
  return axios.get("/managementPlatform/lease/queryInsuranceDetail", {
    params
  });
}
  /**  活动详情*/
  export const queryActivityDetail = params =>
    axios.get("/electricity/lease/activity/queryActivityDetail", {
      params
  });

  /** 查询是否购买保险 */
  export const isInsurance = () => axios.get('/managementPlatform/lease/queryCompulsoryPurchase', {})
// 对账单列表
export const accountCheckList = params => axios.get('/electricity/lease/accountCheck/accountCheckList', {params})
// 对账单详情
export const accountCheckDetail = params => axios.get('/electricity/lease/accountCheck/detail', {params})
// 可结算收益
export const querySettleMoney = params => axios.get('/electricity/lease/accountCheck/querySettleMoney', {params})

// 生成对账单
export const createAccountCheck = params => axios.get('/electricity/lease/accountCheck/createAccountCheck', {params})

// 处理对账单
export const handleOrder = params => axios.get('/electricity/lease/accountCheck/handleOrder', {params})
// 完成确认
export const confirm = params => axios.get('/electricity/lease/accountCheck/confirm', {params})
// 租电订单导出
export const subAccountExport = params => axios.get('/electricity/lease/data/subAccount/export', {params})



                                                                                                                