import axios from "../js/ajax";
/** 分账关系列表 */
export const listDistributeRelation = params =>
  axios.get("/managementPlatform/fund/lease/listDistributeRelation", {
    params,
    loading: true
  });
/* 新增分账关系 */
export const addDisRelation = params =>
  axios.post("/managementPlatform/fund/lease/createDistributeRelation", params);
/* 删除分账关系 */
export const delDisRelation= params =>
  axios.get("/managementPlatform/fund/lease/deleteDistributeRelation", params);
  /** 更新分账 */
  export const editDisRelation = params => axios.post('/managementPlatform/fund/updateDistributeRelation', params);
  