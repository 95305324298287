<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="对账单编号" prop="number">
          <el-input
            placeholder="请输入对账单编号"
            v-model="searchForm.number"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="对账单主体" >
          <AgentTree
            :props="props"
            v-model="unitNos1"
            placeholder="请选择分账主体"
            style="width: 300px"
            :selectKey='selectKey'
          ></AgentTree>
        </el-form-item>
        <el-form-item label="对账单状态" prop="status">
          <el-select ref="searchFormStatus" v-model="searchForm.status" placeholder="请选择对账单状态" clearable>
            <el-option
              v-for="item in DIRECTION_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
          <!-- TO DO -->
          <el-button type="primary" @click="importBtn">导出</el-button>
          <el-button type="primary" @click="reconciliation">发起对账</el-button>

        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="number" fixed width="200" label="对账单编号">
        </el-table-column>
        <el-table-column prop="unitName" width="200" label="对账主体">
        </el-table-column>
        <el-table-column prop="createTime" width="200" label="发起时间">
          <template slot-scope="{row}"}>
            {{row.createTime | timeToDate}}
          </template>
        </el-table-column>
        <el-table-column prop="status" width="200" label="对账单状态">
          <template slot-scope="{row}">
            {{$changeObj(DIRECTION_STATUS)[row.status]}}
          </template>
        </el-table-column>
        <el-table-column prop="money" width="200" label="需结算金额">
        </el-table-column>
        <el-table-column prop="updateMoney" width="200" label="调整项金额">
        </el-table-column>
        <el-table-column prop="realMoney" label="实际返润金额" min-width="150">
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="viewDetail(row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="searchForm.beginIndex"
        :page-size="searchForm.pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="发起对账"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <div class="dfc mb20">
        <span class="mr20">对账主体</span>
         <AgentTree
          :props="props"
          v-model="unitNos2"
          placeholder="请选择分账主体"
          style="width: 300px"
          :selectKey='selectKey'
        ></AgentTree>
      </div>
      <div class="borgray bor-rad incomeBox mb20">
        <p class="incomeTitle ft16 mb30">可结算收益</p>
        <p class="money ft20 ">￥{{settleMoney || 0}}</p>
      </div>
      <p class="mb80">可结算收益：上月可结算的收益，可在10-15日发起结算</p>
      <div>
        <el-button type="primary" @click="confirmLaunch">确认发起</el-button>
        <el-button  @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import  AgentTree from '@/components/AgentTree';

import { accountCheckList,querySettleMoney,createAccountCheck,subAccountExport } from "@/api/goods";
import { timeToDate } from "@/utils/date-format";
import {RECONCILIATION_STATUS,DIRECTION_STATUS} from "@/js/constant"
export default {
  data() {
    return {
      DIRECTION_STATUS,
      RECONCILIATION_STATUS,

      settleMoney:'0',
      unitNos1:[],
      unitNos2:[],
      dialogVisible: false,
       props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      selectKey: '',
      form:{
        unitNo:"",
      },
      searchForm: {//搜索
        number: "",
        unitNo:"",
        status:"",
        beginIndex:1,
        pageSize:20,
      },
      // beginIndex: 1,
      // pageSize: 20,
      tableData: [],
      total: 1
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormStatus.blur()
        _this.onSearch()
      }
    };
    this.accountCheckList();//对账单列表
    // this.querySettleMoney();//可结算收益
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/viewBill') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.accountCheckList();
  },
  watch:{
    'unitNos2':{
        handler:function(){
          this.querySettleMoney();
        }

    }
  },
  methods: {
    async importBtn(){
      try{
        const params = {...this.searchForm};
        const res = await subAccountExport(params);
        if(+res.result === 1){
          this.$router.push('/leaseholdMgmt/exportTask');
        }
      }catch(e){
        console.log(e);
      }
    },

    // 确认发起按钮
    confirmLaunch(){
      this.dialogVisible = false;
      // if(+this.settleMoney === 0){
      //    this.$message.error("没有可结算收益");
      //    return;
      // }
      // if(10 <= new Date().getDate() <= 15){
      //    this.$message.error('当前不在对账日期范围，请在每月10-15日发起对账');
      // }
      // if(this.unitNos2 && this.unitNos2.length === 0){
      //   this.$message.error('请选择对账主体');
      //   return;
      // }
      this.createAccountCheck();//生成对账单
    },
    // 取消
    cancel(){
      this.dialogVisible = false;
    },
    // 点击发起对账(弹窗)
    reconciliation(){
      this.settleMoney = 0;
      this.unitNos2 = [];
      this.dialogVisible = true;

    },
    // 搜索
    onSearch() {
      this.accountCheckList();
    },
    // 生成对账单(确认发起)
    async createAccountCheck(){
      try{
        const params = {
          unitNo:this.unitNos2[this.unitNos2.length - 1]
        }
        const res = await createAccountCheck(params);
        this.accountCheckList();
      }catch(e){
        console.log(e,'e');
      }
    },
    // 可结算收益
    async querySettleMoney(){

      if(this.unitNos2 && this.unitNos2.length === 0){
        return false;
      }
      try{
        const params = {
          unitNo:this.unitNos2[this.unitNos2.length - 1]
        };
        const res = await querySettleMoney(params);
        this.settleMoney = res.mdata.settleMoney;
      }catch(e){
        console.log(e,'e');
      }
    },
    // 对账单列表
    async accountCheckList(){
      console.log(this.searchForm,'searchForm');
      try{
        console.log(this.unitNos1,'this.unitNos1');
        if(this.unitNos1 && this.unitNos1.length !== 0){
          this.searchForm.unitNo = this.unitNos1[this.unitNos1.length - 1];
        }else{
          this.searchForm.unitNo = '';
        }
       const params = {
          ...this.searchForm,
          beginIndex:this.searchForm.beginIndex - 1
       };
       console.log(params.beginIndex,'beginIndex');
       const res = await accountCheckList(params);
       this.tableData = res.ldata;
       this.total = +res.mdata.total;
      }catch(e){
        console.log(e,'e');
      }
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page - 1;
      this.accountCheckList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    viewDetail(row) {
      this.$router.push({
        path: "/leaseholdMgmt/viewbill",
        query: row
        // {
        //   number: row.number,
        //   status:row.status
        // }
      });
    }
  },
  components: {
    AgentTree,
  }
};
</script>

<style scoped>
.el-button--mini{
  padding:9px 15px;
}
.library {
  padding-bottom: 80px;
}
.incomeBox{
  height:150px;
  width:300px;
  padding:20px;
}
.incomeTitle{

}
.money{
  text-align:center;
}
</style>
