<template>
  <div>
     <el-cascader
        v-model="unitNos"
        @change="sendVal"
        :options="agentList"
        v-bind="$attrs"
        :props="uniProps"
        style="width: 300px"
        placeholder="请选择组织"
      >
      </el-cascader>
  </div>
</template>

<script>
import { queryHaiLiUnitTree } from "@/api/common";
export default {
  props: {
    value: {
      default:  () => [],
    },
    extra: {
      default: () => {}
    },
    selectKey: {
      default: ''
    },
    index:{}
  },
  data() {
    return {
      unitNos: [],
      agentList: [],
      uniProps: {
        value: 'unitNo',
        label: 'name',
        checkStrictly: true,
        children: 'subUnit',
      },
      roleOptions: {
        0: '资金方' ,
        1: '代理商',
        2: '门店',
        100: '海狸'
      }
    }
  },
  computed: {
    selectVal() {
      return {
        agentList: this.agentList,
        selectKey: this.selectKey,
      }
    }
  },
  watch: {
    value(val) {
      this.unitNos = val
      this.$emit('input', this.unitNos )
      if (!val) return
      this.handlerRole()
    },
    selectVal: {
      handler(obj) {
        const arr = []
        const uniArr = this.agentList[0]
        if (!uniArr) {
          return
        }
        if (obj.selectKey == uniArr.unitNo) {
          arr.push(uniArr.unitNo)
          this.unitNos = arr
          this.sendVal()
          return
        }
        uniArr.subUnit.forEach(ele => {
          if (ele.unitNo == obj.selectKey) {
            arr.push(uniArr.unitNo, ele.unitNo);
          }
          if (ele.subUnit && ele.subUnit.length) {
            ele.subUnit.forEach(ele2 => {
              if (ele2.unitNo == obj.selectKey) {
                arr.push(uniArr.unitNo, ele.unitNo, ele2.unitNo);
              }
            });
          }
        });
        this.unitNos = arr
        this.sendVal()
      },
      deep: true,
    }
  },
  mounted() {
    this.queryHaiLiUnitTree()
  },
  methods: {
    sendVal() {
      this.$emit('input', this.unitNos )
      this.handlerRole()
      /** 处理角色 */
     
    },
    async queryHaiLiUnitTree() {
      const params = {
        isContainHaiLiUnit: 1,
        isContainFunder: 1,
        ...this.extra,
      };
      const res = await queryHaiLiUnitTree(params);
      if (res.mdata.flag) {
        res.mdata.haiLiUnit.subUnit.forEach(el => {
          el.subUnit && el.subUnit.forEach(el2 => {
            delete el2.subUnit
          })
        })
        this.agentList = [res.mdata.haiLiUnit];
      } else {
        this.agentList = res.ldata;
      }
    },
    handlerRole() {
      const arr = [this.agentList[0]]
      this.agentList[0].subUnit.forEach(el => {
        arr.push(el)
        if (el.subUnit && el.subUnit.length) {
          el.subUnit.forEach(el2 => {
            arr.push(el2)
          })
        }
      })
      const unitNo = this.unitNos[this.unitNos.length - 1]
      const roleInfo = arr.find(el => el.unitNo == unitNo)
      if(roleInfo) {
         this.$emit('sendRole', this.index, this.roleOptions[roleInfo.role])
      }
    }
  },
  components: {

  }
}
</script>

<style scoped>

</style>
